import uniq from "lodash/uniq";

export const UTM_PARAMS = ["utm_source", "utm_medium", "utm_campaign"];

export function scanAnchorTags() {
  const links = document.getElementsByTagName("a");
  const utm = getUtmParams();

  if (!utm || !utm.length) return;

  for (var i = 0; i < links.length; i++) {

    const href = links[i].href.replace(links[i].hash, "");
    
    if (href && href.startsWith("tel:") || href.startsWith("mailto:")) {
      continue;
    }

    links[i].href = setUtmParams(utm, href) + links[i].hash;
  }
}

export const setUtmParams = (utm: string[], href: string): string => {
  const hashRegex = new RegExp("#[^?&#]*");
  const hash = hashRegex.exec(href)?.[0] || "";

  let replacedHref = href.replace(hash, "");
  let uniqUtm = uniq(utm);

  for (let j = 0; j < uniqUtm.length; j++) {
    if (replacedHref.includes("?") && replacedHref.includes(uniqUtm[j]))
      continue;

    if (replacedHref.includes("?")) {
      replacedHref = replacedHref + "&" + uniqUtm[j];
    } else {
      replacedHref = replacedHref + "?" + uniqUtm[j];
    }
  }

  return replacedHref + hash;
};

export const getUtmParams = (): string[] => {
  if (typeof window === "undefined") return [];

  return UTM_PARAMS.map((param) => {
    const regex = new RegExp(`${param}=[^&#]*`);
    const match = regex.exec(window.location.href);
    return match ? match[0] : "";
  }).filter((utm: string) => !!utm);
};
